
/**
 * Secondary Nav
 */
.secondary-nav-menu {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
}

.secondary-nav-menu .nav-item {
    border: 1px solid #dedede;
    flex: 1;
}

.nav-item .link {
    display: flex;
    justify-content: center;
    padding: 1em 0;
    &:hover {
        background-color: #234487;
        color: #fff;
        text-decoration: none;
    }
}

.nav-item .link:hover svg {
    fill: #fff;
}

.nav-item .link svg g {
    fill: inherit !important;
}

.nav-item .link:hover svg g {
    fill: inherit !important;
}

.link .icon {
    margin-right: 10px;
}

.link .title {
    margin-right: 10px;
}