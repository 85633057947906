.contact-profile-photo {
    height: 40px;
    width: 40px;
    border-radius: 50%;
}

.contact-profile-photo g {
    height: 40px;
    width: 40px;
    fill-opacity: 0.1;
}

.contact-header {
    background-color: #dedede;
    height: 80px;
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.contact-header .contact-photo {
    border: 1px solid #000;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    margin: 0 20px;
}

.contact-profile-photo {
    height: 60px;
    width: 60px;
}

.contact-name {
    margin: 0;
}