/**
 * Helpers
 */

.mb-3r {
    margin-bottom: 3rem;
}

.w-100 {
    width: 100%;
}

.mt-a {
    margin-top: auto;
}

.h-100p {
    height: 100%;
}

/**
 * Contact
 */

.user-profile-photo {
    text-align: center;
}

.user-profile-photo img {
    border-radius: 50%;
    border: 1px solid #234487;
    padding: 5px;
    height: 200px;
    width: 200px;
}

.staff-member img {
    border-radius: 50%;
}

.hero {
    background-color: #e4f1fe;
    height: 200px;
}

.organization-header {
    margin-top: -120px !important;
}

@media (min-width: 992px) {
    .profile-main .row {
        display: flex;
    }

    .profile-main .row div[class*="col-md-"] {
        display: flex;
    }
}

/**
 * Member Organization
 */

.member-contact {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
}

.contact-profile {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    margin-top: 22px;
}

svg.contact-profile {
    height: 150px !important;
}

svg.contact-profile g {
    fill-opacity: 0.1;
}

.member-logo {
    height: auto !important;
    width: 100% !important;
    margin-top: 22px;
}

svg.member-logo {
    height: 150px !important;
}

svg.member-logo g {
    fill-opacity: 0.1;
}
