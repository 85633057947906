/*
 * Main Application CSS
 */

@import "variables";

/*
 * Layout
 */

html, body {
    height: 100%;
}

#app {
    height: 100%;
}

.content-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
}

header {
	background-color: #234487;
}

footer {
	margin-top: auto;
	background-color: #ebebeb;
	padding: 5px;
	color: #626262;
}

main {
	padding-bottom: 20px;
}

.wide-container {
	width: 100%;
	display: flex;
	justify-content: center;
}

/**
 * Navigation
 */

.top-nav {
	margin: 0;
	background-color: #fff;
}

.main-navbar {
	margin: 0;
}

.main-nav {
	background-color: transparent;
}

.main-nav > li > a {
	color: #fff !important;
    font-weight: bold;
    font-size: 16px;
    &:hover {
	    background: rgba(255, 255, 255, .2) !important;
    }
}

.main-nav .nav .navbar-nav > li > a {
    color: #fff !important;
}

.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:focus, .navbar-default .navbar-nav>.open>a:hover {
	background: rgba(255, 255, 255, .2) !important;
}

.footer-nav {
	background-color: transparent;
}

.footer-nav > li > a {
	color: #626262;
	&:hover {
		background-color: transparent;
		color: #222;
	}
}

/*
 * Header elements
 */

.logo {
	float: left;
	img {
		height: 80px;
		width: auto;
	}
	a {
		padding: 0;
	}
}

/*
 * Footer elements
 */

.copyright {
	padding: 0;
    margin-top: 1em;
}

@media (max-width: 480px) {
	footer {
		font-size: 12px;
	}
}

@media (max-width: 768px) {
	.copyright {
		float: none !important;
	}
}

/**
 * Carousel
 */

.carousel-control {
    top: 80%;
}

.chevron-left {
    position: absolute;
    top: 50%;
    margin-top: -10px;
    z-index: 5;
    display: inline-block;
}

.chevron-right {
    position: absolute;
    top: 50%;
    margin-top: -10px;
    z-index: 5;
    display: inline-block;
}

.carousel-inner > .item {
    position:relative;
}

.carousel-inner > .item > img {
    min-height: 250px;
}

.carousel-caption {
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 20%;
    left: 20px;
    width: 40%;
    max-height: 175px;
    padding: 20px 10px;
}

.carousel-caption > .title {
	font-weight: bold;
    font-size: 28px;
    color: #fff;
}

.carousel-caption > p {
    color: #fff;
    font-size: 18px;
}
@media(max-width: 650px) {
    .carousel-caption {
        top: 10%;
        width: 92%;
    }
}

/*
 * General page elements
 */

h1.page-header {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

/*
 * Home Page
 */

.home-stripe {
    background-color: #fcd22b;
    color: #000;
}

/*
 * Login page
 */

.login {
	margin-top: 30px;
}

/*
 * Registration page
 *
 */

.create-account {
	margin-top: 10px;
}

/*
 * Profile
 */

.profile-nav {
    margin: 1rem;
}

.profile-nav-item {
    border-bottom: 1px dotted #cdcdcd;
    padding: 1rem;
}

.profile-nav-item > a {
    text-decoration: none;
}

img.profile-photo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 1px solid #234487;
}

#profilePhoto {
    display: block;
    width: 100%;
    min-height: 8em;
    padding: .5em;
    background-color: #fff;
    border: 1px solid #ccd0d2;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    text-align: center;
}

#profilePhoto.has-error {
    border-color: #a94442;
}

section {
    background-color: #fff;
    padding: 40px 20px;
}

.d-flex {
    display: flex;
}

.m-0 {
    margin: 0;
}

.mb-0 {
    margin-bottom: 0;
}

.mt-1 {
    margin-top: 1rem;
}

.mb-1 {
    margin-bottom:1rem;
}

.mb-2 {
    margin-bottom: 2rem;
}

.panel-heading.bg-success {
    background-color: $bg-success !important;
}

.btn > .icon {
    margin-right: 5px;
}

a svg {
    fill: #3097D1;
}

a:hover svg {
    fill: #216a94;
}