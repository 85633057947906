
/*
 * Layout
 */

.application-question  {
    margin: 1em;
    padding: 1em 2em 1em;
    border-top: 1px solid #e3e3e3;
    background: #fff;
}

/*
 * Progress Indicator
 */

.application-progress {
    margin: 10px 0;
    display:flex;
    justify-content: space-around;
}

.page {
    text-align: center;
    width: 10em;
    &.completed > .icon {
        border: 1px solid #000;
        background-color: #d3d3d3;
    }
    &.active > .icon {
        border: 1px solid #000;
    }
}

.page > .progress {
    height: 2px;
    z-index: 1;
    margin: 0;
    margin-left: 4em;
    position: absolute;
    width: 7em;
    top: 1.5em;
}

.progress-complete {
    height: 100%;
    background-color: green;
}

.page > .icon {
    z-index: 2;
    background-color: #f3f3f3;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.page > .title {
    font-weight: bold;
    font-size: 12px;
}
@media(min-width: 544px) {
    .page > .progress {
        margin: 0;
        margin-left: 4em;
        width: 7em;
        top: 1.5em;
    }
}

@media(min-width: 768px) {
    .page > .progress {
        margin: 0;
        margin-left: 7em;
        width: 10em;
        top: 2.3em;
    }

    .page > .icon {
        width: 3em;
        height: 3em;
    }

    .page > .title {
        font-weight: bold;
        font-size: 16px;
    }
}

@media(min-width: 1000px) {
    .page > .progress {
        top: 3em;
    }
}

/*
 * General Formatting
 */

.question-title {
    margin-top: 22px;
    margin-bottom: 1em;
    padding-bottom: .8em;
    border-bottom: 1px dotted #ddd;
}

.application-question > .help-text {
    margin: .1rem;
    padding-left: .1em;
    font-size: 12px;
}

/*
 * Form Elements
 */

form.membership-application {
    & fieldset {
        margin-top: 1em;
    }
    & legend {
        margin: 1em 0;
        border-bottom: 1px dotted #ddd;
        color: #636b6f;
        font-size: 18px;
    }
}

.checkbox-blocks {
    margin-top: 1em !important;
}

.checkbox {
    &.checkbox-block {
        padding: 0;
        text-align: center;
        height: 100%;
    }
    &.checkbox-block > input[type="checkbox"] {
        visibility: hidden;
        &:checked + label {
            background-color: #aaa;
            color: #fff;
        }
    }
    &.checkbox-block > label {
        width: 100%;
        min-height: 42px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #eee;
        border: 1px solid #fff;
        padding: .3em;
        &:hover {
            background-color:#e3e3e3;
        }
    }
}

#organizationLogo {
    display: block;
    width: 100%;
    min-height: 8em;
    padding: .5em;
    background-color: #fff;
    border: 1px solid #ccd0d2;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    text-align: center;
}

#organizationLogo.has-error {
    border-color: #a94442;
}
