.user-profile-photo img {
    border-radius: 50%;
    border: 1px solid #234487;
    padding: 5px;
    height: 200px;
    width: 200px;
}

.profile-photo-sm g {
    height: 50px;
    width: 50px;
    fill-opacity: 0.1;
}