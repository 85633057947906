/*
 * Resource Library General
 */
.resource-library-sidebar {
    border-right: 1px solid #d3e0e9;
}

.resource-library-sidebar .title {
    font-size: 20px;
}

.resource-article {
    border-bottom: 1px solid #EEE;
    padding-bottom: 1.5rem;
}

.menu-label {
    margin-top: 2.5em;
    margin-bottom: 1em;
}

/*
 * Resource Library Nav
 */
.nav-menu {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
}

.nav-menu .nav-item {
    border: 1px solid #dedede;
    flex: 1;
}

.nav-item .link {
    display: flex;
    justify-content: center;
    padding: 1em 0;
    &:hover {
        background-color: #234487;
        color: #fff;
        text-decoration: none;
    }
}

.nav-item .link:hover svg {
    fill: #fff;
}

.nav-item .link svg g {
    fill: inherit !important;
}

.nav-item .link:hover svg g {
    fill: inherit !important;
}

.link .icon {
    margin-right: 10px;
}

.link .title {
    margin-right: 10px;
}

/*
 * Resource Library Index
 */

.resource-button {
    width: 50%;
    margin: 0 5px;
}

/*
 * General Detail Page
 */
.edit-resource-btn {
    margin-top: 22px;
}

.resource-description {
    margin-top: 2em;
}

.resource-meta {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    padding: 1em;
    margin-top: 1em;
}

.resource-button-group {
    border-top: 1px dotted #ddd;
    border-bottom: 1px dotted #ddd;
    margin: 1rem 0;
    padding: 1rem;
    text-align: center;
}

/*
 * Collection Details
 */

.articles-container {
    background-color: #eee; padding: 2rem;
}

.collections {
    display: flex;
    flex-wrap: wrap;
}

.tile.parent {
    width: 50%;
    display: flex;
}

@media (max-width: 768px) {
    .tile.parent {
        width: 100% !important;
    }
}
.tile.child {
    padding: 0 1em;
    border: 1px solid #dedede;
    margin: .5em;
    width: 100%;
}

.tile.child:hover {
    border: 1px solid #234487;
}

.collection-item a {
    display: block;
    text-decoration: none;
    color: inherit;
}
.title {
    color: #3097d2;
}

/*
 * Article Details
 */

.materials-container {
    background-color: #eee; padding: 2rem;
}

.filter-title {
    margin-top: .5rem;
    font-size: 18px;
    padding-left: 1.5rem;
    color: #636b6f !important;
}

.panel-title .filter-title:before {
    font-family: 'Glyphicons Halflings';
    content: "\e114";
    float: left;
}

.panel-title .filter-title.collapsed:before {
    content: "\e080";
}