.block {
  @include block; }

.container {
  position: relative;
  @include desktop {
    margin: 0 auto;
    max-width: 960px;
    // Modifiers
    &.is-fluid {
      margin: 0 20px;
      max-width: none; } }
  @include widescreen {
    max-width: 1200px; } }

.delete {
  @include delete; }

.fa {
  font-size: 21px;
  text-align: center;
  vertical-align: top; }

.icon {
  @include fa(21px, 24px);
  .fa {
    font-size: inherit;
    line-height: inherit; }
  // Sizes
  &.is-small {
    @include fa(14px, 16px); }
  &.is-medium {
    @include fa(28px, 32px); }
  &.is-large {
    @include fa(42px, 48px); } }

.heading {
  display: block;
  font-size: 11px;
  letter-spacing: 1px;
  margin-bottom: 5px;
  text-transform: uppercase; }

.highlight {
  @include block;
  font-size: 12px;
  font-weight: normal;
  max-width: 100%;
  overflow: hidden;
  padding: 0;
  pre {
    overflow: auto;
    max-width: 100%; } }

.loader {
  @include loader; }

.number {
  background-color: $background;
  border-radius: 290486px;
  display: inline-block;
  font-size: $size-medium;
  vertical-align: top; }

.tag {
  align-items: center;
  background-color: $background;
  border-radius: 290486px;
  color: $text;
  display: inline-flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  line-height: 16px;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: top;
  white-space: nowrap;
  .delete {
    margin-left: 4px;
    margin-right: -6px; }
  // Colors
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);
    &.is-#{$name} {
      background-color: $color;
      color: $color-invert; } }
  // Sizes
  &.is-small {
    font-size: $size-small;
    height: 20px;
    padding-left: 8px;
    padding-right: 8px; }
  &.is-medium {
    font-size: $size-normal;
    height: 32px;
    padding-left: 14px;
    padding-right: 14px; }
  &.is-large {
    font-size: $size-5;
    height: 40px;
    line-height: 24px;
    padding-left: 18px;
    padding-right: 18px;
    .delete {
      margin-left: 4px;
      margin-right: -8px; } } }
