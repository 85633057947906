////////////////////////////////////////////////
////////////////////////////////////////////////
// 1. Initial variables

// Colors
$black:        hsl(0, 0%, 4%) !default;
$black-bis:    hsl(0, 0%, 7%) !default;
$black-ter:    hsl(0, 0%, 14%) !default;

$grey-darker:  hsl(0, 0%, 21%) !default;
$grey-dark:    hsl(0, 0%, 29%) !default;
$grey:         hsl(0, 0%, 48%) !default;
$grey-light:   hsl(0, 0%, 71%) !default;
$grey-lighter: hsl(0, 0%, 86%) !default;

$white-ter:    hsl(0, 0%, 96%) !default;
$white-bis:    hsl(0, 0%, 98%) !default;
$white:        hsl(0, 0%, 100%) !default;

$orange:       hsl(14,  100%, 53%) !default;
$yellow:       hsl(48,  100%, 67%) !default;
$green:        hsl(141, 71%,  48%) !default;
$turquoise:    hsl(171, 100%, 41%) !default;
$blue:         hsl(217, 71%,  53%) !default;
$purple:       hsl(271, 100%, 71%) !default;
$red:          hsl(348, 100%, 61%) !default;

// Typography
$family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif !default;
$family-monospace: "Inconsolata", "Consolas", "Monaco", monospace !default;

$size-1: 48px !default;
$size-2: 40px !default;
$size-3: 28px !default;
$size-4: 24px !default;
$size-5: 18px !default;
$size-6: 14px !default;

$size-7: 11px !default;

$weight-normal: 400 !default;
$weight-bold: 700 !default;
$weight-title-normal: 300 !default;
$weight-title-bold: 500 !default;

// Miscellaneous
$easing: ease-out !default;
$radius-small: 2px !default;
$radius: 3px !default;
$radius-large: 5px !default;
$speed: 86ms !default;

////////////////////////////////////////////////
////////////////////////////////////////////////
// 2. Primary colors

$primary: $turquoise !default;

$info: $blue !default;
$success: $green !default;
$warning: $yellow !default;
$danger: $red !default;

$light: $white-ter !default;
$dark: $grey-darker !default;

////////////////////////////////////////////////
////////////////////////////////////////////////
// 3. Applied variables

// Invert colors
$primary-invert: findColorInvert($primary) !default;

$info-invert: findColorInvert($info) !default;
$success-invert: findColorInvert($success) !default;
$warning-invert: findColorInvert($warning) !default;
$danger-invert: findColorInvert($danger) !default;

$light-invert: $dark !default;
$dark-invert: $light !default;

// General colors
$body-background: $white-ter !default;

$background: $white-ter !default;

$border: $grey-lighter !default;
$border-hover: $grey-light !default;

// Text colors
$text: $grey-dark !default;
$text-invert: findColorInvert($text) !default;
$text-light: $grey !default;
$text-strong: $grey-darker !default;

// Code colors
$code: $red !default;
$code-background: $background !default;

$pre: $text !default;
$pre-background: $background !default;

// Link colors
$link: $primary !default;
$link-invert: $primary-invert !default;
$link-visited: $purple !default;

$link-hover: $grey-darker !default;
$link-hover-background: $white-ter !default;
$link-hover-border: $grey-darker !default;

$link-active: $grey-darker !default;
$link-active-border: $grey-darker !default;

// Typography
$family-primary: $family-sans-serif !default;
$family-code: $family-monospace !default;

$size-small: $size-7 !default;
$size-normal: $size-6 !default;
$size-medium: $size-5 !default;
$size-large: $size-3 !default;
$size-huge: $size-1 !default;

////////////////////////////////////////////////
////////////////////////////////////////////////
// 4. Lists and maps

$colors: (white: ($white, $black), black: ($black, $white), light: ($light, $light-invert), dark: ($dark, $dark-invert), primary: ($primary, $primary-invert), info: ($info, $info-invert), success: ($success, $success-invert), warning: ($warning, $warning-invert), danger: ($danger, $danger-invert)) !default;

$sizes: $size-1 $size-2 $size-3 $size-4 $size-5 $size-6 !default;
